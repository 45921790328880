<template>
  <base-section id="theme-features">
    <base-section-heading title="Our Speciality">
      Our work speaks for us. Our designers are highly-trained and creative at the core, and they spend considerable amount of time in understanding market trend and recongnizing customer focussed designs. It'll be a guaranteed success for your brand promotion.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
           <!-- {{ i.text }}  Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis illum veniam cum dolores ratione commodi beatae quas maxime, laboriosam excepturi solut! -->
          {{ i.text }} Whether you are looking to promote your business, building a brand image or need help with social media posts we are here to help you. Message us directly or use the form to send your requirements. Our support team is top-notch, and we're here to answer any questions.
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          color: 'primary',
          dark: true,
          title: 'Intuitive Designs',
          icon: 'mdi-fountain-pen-tip',
          text: 'We believe in magic, thats what reflects in our designs too. We take care of basics first and focus on adding the magic to every post we deliver.',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Mobile First',
          icon: 'mdi-cellphone',
          text: 'All our designs are designed keeping mobile-first mindset. 90% of customer views on marketing materials are viewed via mobile devices.',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Top Class Quality',
          icon: 'mdi-pencil-box-outline',
          text: 'We never accept anything less than top class quality as our deliverables. We offer the best possible premium deliverable at every instance.',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Creativity At Core',
          icon: 'mdi-image-size-select-actual',
          text: 'Our designers core mantra is creativity. We measure our success not with the numbers, but with the impact what each design produces.',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Stress Free',
          icon: 'mdi-ice-pop',
          text: 'We offer complete management of social media accounts. Leave it to us and our dedicated team will take care of all your social media presence and promotion.',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Customer Support',
          icon: 'mdi-help-circle-outline',
          text: 'Our top-notch customer care takes care of any questions you have about our product or services.',
        },
      ],
    }),
  }
</script>
